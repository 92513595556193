import { createContext, useContext, useEffect, useRef, useState } from "react";

import i18n from "../i18n";

const LangContext = createContext();
const SetLangContext = createContext();
export const useSetLang = () => {
  const setLang = useContext(SetLangContext);
  return setLang;
};
export const useLang = () => {
  const lang = useContext(LangContext);
  return lang;
};

const LangProvider = ({ children }) => {
  const [lang, setLang] = useState("es");

  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <SetLangContext.Provider value={setLang}>
      <LangContext.Provider value={lang}>{children}</LangContext.Provider>
    </SetLangContext.Provider>
  );
};

export default LangProvider;
