import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { Link } from "react-router-dom";
import Sprite from "../../assets/images/svg/sprite.svg";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import UserLogOut from "components/UserLogOut/UserLogOut";
import { getIsAuth } from "redux/auth/AuthSelector";
import styles from "./Header.module.css";
import { useLang } from "context/LangProvider";
import { useSelector } from "react-redux";

const Header = () => {
  const isLoggedIn = useSelector(getIsAuth);
  const lang = useLang();
  return (
    <>
      <header className={styles.header}>
        <div className={styles.headerWrapper}>
          <Link to="/" alt="homepage" className={styles.logoWrapper}>
            <svg className={styles.logo} alt="logo" width="90" height="31">
              {lang === "es" && <use href={`${Sprite}#icon-logo-es`}></use>}
              {lang === "en" && <use href={`${Sprite}#icon-logo`}></use>}
            </svg>
          </Link>
          <ThemeSwitcher />
          <LanguageSwitcher />
          {isLoggedIn && <UserLogOut />}
        </div>
      </header>
    </>
  );
};
export default Header;
