import "react-toastify/dist/ReactToastify.css";

import { Outlet, useLocation } from "react-router-dom";

import Header from "components/Header/Header";
import { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import clsx from "clsx";
import s from "./SharedLayout.module.css";
import { useLang } from "context/LangProvider";

const SharedLayout = () => {
  const { pathname } = useLocation();
  const lang = useLang();

  return (
    <>
      <Header />
      <section className={pathname === "/" ? s.section : s.sectionHome}>
        <div
          className={
            pathname === "/" ? s.BottomHomeCabaggeMain : s.BottomHomeCabagge
          }
        >
          <div className={s.container}>
            <div className={pathname === "/" ? s.background : s.backgroundHome}>
              {pathname === "/" && (
                <div className={s.bottomSection}>
                  <h1 className={clsx(s.title, lang === "en" && s.titleEn)}>
                    {lang === "en" && "Kapusta"}
                    {lang === "es" && "Biyuyo"}
                  </h1>
                  <p className={s.tittleText}>Smart Finance</p>
                </div>
              )}

              <Suspense>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default SharedLayout;
