import clsx from "clsx";
import colombian from "../../assets/images/languages/colombian.png";
import english from "../../assets/images/languages/english.png";
import s from "./LanguageSwitcher.module.css";
import { useSetLang } from "context/LangProvider";

export default function LanguageSwitcher() {
  const setLang = useSetLang();

  return (
    <div>
      <button className={s.loclIcon} onClick={() => setLang("en")}>
        <img className={s.icon} src={english} alt="" width="30" />
      </button>
      <button className={s.loclIcon} onClick={() => setLang("es")}>
        <img
          className={clsx(s.icon, s.iconHeight)}
          src={colombian}
          alt=""
          width="30"
        />
      </button>
    </div>
  );
}
