import "./index.css";
import "./i18n";

import { App } from "components/App";
import { BrowserRouter } from "react-router-dom";
import LangProvider from "context/LangProvider";
import Loader from "./components/Loader/Loader";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import { Suspense } from "react";
import { store } from "redux/store";

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <Suspense fallback={<Loader />}>
      <BrowserRouter basename="/">
        <LangProvider>
          <App />
        </LangProvider>
      </BrowserRouter>
    </Suspense>
    {/* </React.StrictMode> */}
  </Provider>
);
